import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { List, Map, fromJS } from "immutable";

import * as dataRequestActions from './core/designer/datarequest/actions';
import * as designerActions from './core/designer/actions/DesignerActions';
import * as computedPropsActions from './core/designer/actions/computedPropsActions';
import Viewer from './core/designer/components/view/DesignerViewPage';
import DesignLoader from './core/designer/components/DesignLoader';

function getDesignOverrideConfig(datasourceConfig) {
    if (!datasourceConfig) return Map();
    return Map().set('selector-configs', fromJS(datasourceConfig));
}

function DesignerViewerComponent(props) {
    const {
        widgetUuid,
        widgetDesignId,
        widgetActions,
        datasourceConfig,
        refreshCount,
        ...storeProps
    } = props;

    // show the design from the widget config, overriding
    // the selector-config
    useEffect(() => {
        const overrideConfig = getDesignOverrideConfig(datasourceConfig);
        storeProps.showDesignData(widgetDesignId, Map(), overrideConfig);
    }, [widgetDesignId, datasourceConfig]);

    return (
        <DesignLoader>
            <Viewer
                canvasId={widgetUuid}
                widgetUuid={widgetUuid}
                onLoadingChanged={widgetActions.setLoading}
                refreshCount={refreshCount}
                { ...storeProps }
            />
        </DesignLoader>
    );
}

DesignerViewerComponent.propTypes = {
    designId: PropTypes.string.isRequired,
    widgetUuid: PropTypes.string.isRequired,
    refreshCount: PropTypes.number.isRequired,
    widgetActions: PropTypes.object.isRequired
}

function mapStateToProps(state, ownProps) {
    const designer = state.designer;
    const displayDesignList = designer.get('displayListDesign', List());
    const designId = ownProps.designId || designer.getIn(['selected-design', 'uuid']);
    const version = designer.getIn(['web-components', designId, 'version']);
    const description = designer.getIn(['web-components', designId, 'description']);
    const splitPane = designer.get('splitPane');
    const designs = designer.get('designs');
    const design = designs && designs.get(designId);

    return {
        designer,
        displayDesignList,
        widgetDesignId: ownProps.designId,
        designId,
        version,
        description,
        splitPane,
        designs,
        design
    };
}

export default connect(
    mapStateToProps,
    {
        ...designerActions,
        ...dataRequestActions,
        ...computedPropsActions
    }
)(DesignerViewerComponent)
