import React from 'react'
import PropTypes from 'prop-types';
import { Set, Map, List, fromJS } from 'immutable'
import { ReactReduxContext } from 'react-redux';

import DesignerCanvasFigures from './DesignerCanvasFigures'
import * as DesignerHelper from '../helper/DesignerHelper'
import { afterResize, getPosition } from './utils/afterResize';
import { isCanvasSelectionUnchanged } from '../helper/selectionHelpers';
import { getFigureId, getFigurePropMainConfig } from '../helper/figureConfigHelper';
import Draw2dFiguresSync from './utils/Draw2dFiguresSync';
import { isGroupFigure } from './utils/canvasFigureUtils';
import { PROPERTY_FUNCTIONS } from './utils/canvasPropertyFunctions';
import ViewModeSelectionPolicy from './utils/ViewModeSelectionPolicy';
import AnchorPointParentLocator, { updatePosition } from './utils/AnchorPointParentLocator';
import { getDefaultValue } from '../helper/DesignerHelper';
import { createTimer } from '../helper/perfMonitor';
import PdfFigure from './utils/PdfFigure';
import _ from 'lodash';
/*global draw2d*/

// these properties must always be set when updating a figure,
// because draw2d doesn't check if these values are included and just sets them
const REQUIRED_PROPS = ['id', 'x', 'y', 'target', 'source']
// these values change the layout of a figure, requiring a repaint
const LAYOUT_PROPS = ['x', 'y', 'width', 'height', 'angle']
const CONNECTION_CLASS = 'draw2d.Connection'

const updateFiguresCanvasTimer = createTimer('update-figures-canvas');

/**
 * Wrapper class for the draw2d canvas object
 * This is the only class which directly interacts with
 * the draw2d canvas and figures.
 */

class DesignerCanvas extends React.Component {

  constructor() {
    super();
    this.draw2dCanvas = null;

    // draw2dFigures: draw2d only keeps a list of figures,
    // lookup will be slow when many figures are on the canvas
    this.draw2dFigures = Map();

    // list of calculated figure results
    // that couldn't be added to the canvas yet because parent not yet added
    this.childrenAddLater = Map();

    // bind callbacks to the current instance
    this.updateDraw2DFiguresBulk = this.updateDraw2DFiguresBulk.bind(this);
    this.deleteDraw2DFiguresBulk = this.deleteDraw2DFiguresBulk.bind(this);
    this.updateDraw2DFiguresOrderBulk = this.updateDraw2DFiguresOrderBulk.bind(this);
  }

  // clean up the draw2d canvas object
  componentWillUnmount() {
    // don't capture selection or removal at this point:
    this.draw2dCanvas.off('select')
    this.draw2dCanvas.off('figure:remove')
    this.draw2dCanvas.destroy()

    // remove resize handler
    this.clearHandleResize();
  }

  // load the required libraries (may be possible to remove
  // when we are able to dynamically require the scripts
  // needed by draw2d)
  UNSAFE_componentWillMount() {
    this.childrenAddLater = Map();

    // re-initialize the figure sync state
    // when the canvas is mounted
    this.figuresSync = new Draw2dFiguresSync();
  }

  // attach a draw2d canvas to the designer-canvas-designId DOM element
  componentDidMount() {
    this.draw2dCanvas = new draw2d.Canvas('designer-canvas-' + this.props.canvasId)
    // capture select events:
    this.draw2dCanvas.on('select', ((emitter, event) => {
      this.handleFigureSelected()
    }).bind(this))
    // capture add to keep in our figure lookup map
    this.draw2dCanvas.on('figure:add', ((emitter, { figure }) => {
      this.draw2dFigures = this.draw2dFigures.set(figure.id, figure);
      updatePosition(figure);

    }).bind(this))
    // capture remove to delete from our figure lookup map
    this.draw2dCanvas.on('figure:remove', ((emitter, { figure }) => {
      this.draw2dFigures = this.draw2dFigures.delete(figure.id);

    }).bind(this))
    // install edit policy to create a manhatten connection by default:
    this.draw2dCanvas.installEditPolicy(new draw2d.policy.connection.DragConnectionCreatePolicy({
      createConnection: () => {
        const defaults = this.props.design.getIn(['defaults', CONNECTION_CLASS])
        const router = eval('new ' + defaults.get('router') + '()')
        return new draw2d.Connection(defaults.set('router', router).toJS())
      }
    }));
    // listen to the command post executions to propagate changes to the react props:
    this.draw2dCanvas.getCommandStack().addEventListener(((event) => {
      if (draw2d.command.CommandStack.POST_EXECUTE == event.details) {
        this.handleCommandPostExecute(event.command)
      }
    }).bind(this))

    // add window resize handler
    this.clearHandleResize = afterResize(this.handleResize.bind(this));

    if (this.props.mode === 'View') {
      // use a custom selection policy to allow drilldown behaviour to work in view mode
      this.draw2dCanvas.installEditPolicy(new ViewModeSelectionPolicy());
    } else {
      this.addContextMenuEventListener();
    }

    // re-render now that this.draw2dCanvas is no longer null to
    // generate the DesignerCanvasFigure children (which will update the canvas
    // with the initial figures)
    this.forceUpdate()
  }

  addContextMenuEventListener() {
    if (this.canvasDiv) {
      this.canvasDiv.addEventListener('contextmenu', (e) => {
        e.preventDefault();
        const position = getPosition(e);
        this.props.setCanvasContextMenuToggled(true, position);
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.design = nextProps.design;

    this.figuresSync.applyChanges(this.draw2dCanvas, this.draw2dFigures, nextProps);

    // recompute all figure props when the datasource results changed
    if(nextProps.design && this.props.design){
      if (nextProps.design.get('datasource-results') !== this.props.design.get('datasource-results')) {
        nextProps.recomputeFigureProps(this.props.designId)
      }
    }
  }

  handleResize() {
    this.props.setWindowSize(
      window.innerWidth,
      window.innerHeight
    );
  }

  render() {
    // render the div which the draw2dCanvas will 'replace' and the
    // figures which will propagate the change diffs to the update/delete functions
    let divId = 'designer-canvas-' + this.props.canvasId
    const overflow = this.props.mode === 'View' ? 'hidden' : 'auto';
    return <div className="wrap-designer-canvas" style={{ overflow }}>
      <div id={divId} className="designer-canvas"
        // keep a reference to the DOM element
        ref={(dom) => this.canvasDiv = dom} />
      {this.renderFigures()}
    </div>
  }

  renderFigures() {
    // when null, this is the first render, skip creating the children because they
    // can't call updateDraw2DFigure/deleteDraw2DFigure until the canvas was instantiated
    if (this.draw2dCanvas == null) {
      return List()
    }

    return <DesignerCanvasFigures
      designId={this.props.designId}
      updateDraw2DFiguresBulk={this.updateDraw2DFiguresBulk}
      deleteDraw2DFiguresBulk={this.deleteDraw2DFiguresBulk}
      updateDraw2DFiguresOrderBulk={this.updateDraw2DFiguresOrderBulk}/>
  }

  deleteDraw2DFiguresBulk(figureIds) {
    // the figure has been removed from our data, also remove it
    // from the draw2dCanvas if it still existed on the draw2dCanvas
    for (let figureId of figureIds) {
      let draw2dFigure = this.draw2dFigures.get(figureId)
      if (draw2dFigure != null) {
        // delete from the register manually, to ensure that child figures are actually removed from the map,
        // as the on:removed callback is not called for child figures.
        this.draw2dFigures = this.draw2dFigures.delete(figureId);
        if (draw2dFigure.parent) 
          draw2dFigure.parent.remove(draw2dFigure)
        this.draw2dCanvas.remove(draw2dFigure)
      }
    }    
  }

  registerChildAddLater(update) {
    const parentId = update.parentId;
    this.childrenAddLater = this.childrenAddLater
      .update(parentId, List(), (children) => children.push(update));
  }

  updateDraw2DFiguresOrderBulk(orderedFigures) {
    let prevZOrder = null;
    for(let figureId of orderedFigures) {
      const figure = this.draw2dFigures.get(figureId);
      if(figure == null || figure.shape == null) {
        console.warn('Error updating figures ordering: Figure not found on canvas with id - ' + figureId);
        return;
      }

      const zOrder = figure.getZOrder();
      if(zOrder < prevZOrder) {
        figure.toFront();
        prevZOrder = figure.getZOrder();
      } else {
        prevZOrder = zOrder;
      }
    }
  }

  updateDraw2DFiguresBulk(bulkUpdates) {
    const timer = updateFiguresCanvasTimer.start();
    for (let update of bulkUpdates) {
      this.updateDraw2DFigure(update);
    }
    timer.stop();
    this.figuresSync.applyChanges(this.draw2dCanvas, this.draw2dFigures, this.props);
  }

  updateDraw2DFigure(update) {
    const { figureId, figResult, figDiff, parentId } = update;

    // if the figure exists on the draw2dCanvas, then update, otherwise insert:
    let draw2dFigure = this.draw2dFigures.get(figureId)
    if (draw2dFigure != null) {
      //console.log(JSON.stringify(figDiff, null, 2))
      // ignore group figure prop changes, since only used for figure bounds calculation
      // apply props which aren't covered by the draw2d setPersistentAttributes:
      let remainingFigDiff = this.updateDraw2DFigureNonPersistentProps(draw2dFigure, figDiff)
      if (!remainingFigDiff.isEmpty() && !isGroupFigure(draw2dFigure)) {
        // there seem to changes to persistent properties, apply the remaining diff:
        this.updateDraw2DFigurePersistentProps(draw2dFigure, figResult, remainingFigDiff)
      }
      // when x/y/width/height/angle changes and the figure has ports, then the ports
      // need to be recalculated visually, otherwise they won't be moved/repainted:
      let portsLayoutChanged = draw2dFigure.layoutPorts() !== undefined
        && LAYOUT_PROPS.some((v) => figDiff.has(v))
      if (portsLayoutChanged) {
        this.updatePortsLayout(draw2dFigure)
      }
    } else {
      let parent;
      if (parentId != null) {
        parent = this.draw2dFigures.get(parentId);
        if (parent == null) {
          // the parent hasn't been added to the canvas yet,
          // so store the parameters, which will be used to
          // initialize a new figure when the parent is finally added.
          this.registerChildAddLater(update);
          return;
        }
      }

      const stack = [];
      stack.push([parent, update]);
      while (stack.length > 0) {
        const [parent, { figureId, figResult, figDiff, configId }] = stack.pop();

        // taken from json.io.writer:createFigureFromType in draw2d v6
        const type = figResult.get('type');
        let draw2dFigure;
        if(type === 'custom.PDF') {
          draw2dFigure = new PdfFigure();
        } else {
          draw2dFigure = eval('new ' + figResult.get('type') + '()');
        }

        // initialize figure attributes / props
        draw2dFigure.setUserData(Map({ configId }));

        this.updateDraw2DFigurePersistentProps(draw2dFigure, figResult, figDiff);
        this.updateDraw2DFigureNonPersistentProps(draw2dFigure, figDiff);

        draw2dFigure.attr({ onDoubleClick: () => this.figureOnDblClick(figureId) })
        draw2dFigure.id = figureId;

        // add figure to the canvas or parent-figure
        if (parent == null) {
          this.draw2dCanvas.add(draw2dFigure);

        } else {
          parent.add(draw2dFigure, new AnchorPointParentLocator());
          this.draw2dFigures = this.draw2dFigures.set(figureId, draw2dFigure);
        }

        // initialize children of the figure
        const children = this.childrenAddLater.get(figureId);
        if (children) {
          this.childrenAddLater = this.childrenAddLater.delete(figureId);
          for (let child of children) {
            stack.push([draw2dFigure, child]);
          }
        }
      }
    }
  }

  // covers setting any properties we didn't take into account specifically in PROPERTY_FUNCTIONS
  updateDraw2DFigurePersistentProps(draw2dFigure, figResult, figDiff) {

    // override resolvable functions with the current value from the figure.
    // The resolved values will be set when updating non-persistent props.
    figDiff = figDiff
      .set('x', draw2dFigure.x)
      .set('y', draw2dFigure.y)
      .set('width', draw2dFigure.width)
      .set('height', draw2dFigure.height);

    //console.log(JSON.stringify(figDiff, null, 2))
    // properties such as id/x/y/... are always required when setting persistent atts
    // (even if they didn't change, because the setPersistentAttributes doesn't check
    // whether they are defined or not and just applies or uses them)
    REQUIRED_PROPS.forEach((k) => {
      if (!figDiff.has(k) && figResult.has(k))
        figDiff = figDiff.set(k, figResult.get(k))
    })
    draw2dFigure.setPersistentAttributes(figDiff.toJS())
  }

  // apply the properties we know we can apply without resorting to using setPersistentAttributes
  // (additionaly, some of the properties simply can't be handled by setPersistentAttributes
  // because their implementation doesn't always cover all of their properties)
  updateDraw2DFigureNonPersistentProps(draw2dFigure, figDiff) {
    figDiff.forEach((newValue, propKey) => {
      // if property is covered and the value has changed, apply it:
      let fn = PROPERTY_FUNCTIONS.get(propKey)
      if (fn !== undefined) {
        let prevValue = fn.valueFn(draw2dFigure)
        if (newValue != prevValue) {
          // when the value was removed, reset to the default value.
          if (newValue == null)
            newValue = getDefaultValue(propKey, null);

          const context = this._getPropertyContext();
          fn.applyFn(draw2dFigure, newValue, context);
        }
        // remove property from diff because it's handled
        figDiff = figDiff.delete(propKey)
      }
    })

    // update the figure bounds resulting from the property changes
    if(draw2dFigure.shape != null)
      updatePosition(draw2dFigure);

    // return the remaining diff, these will need to be handled by
    // updateDraw2DFigurePersistentProps
    return figDiff
  }

  // when the layout has changed and ports exist, we need to instruct
  // the draw2d library to recalculate and repaint the port positions
  updatePortsLayout(figure) {
    figure.portRelayoutRequired = true
    figure.repaint()
    figure.editPolicy.each(((i, e) => {
      if (e instanceof draw2d.policy.figure.DragDropEditPolicy) {
        e.moved(this.draw2dCanvas, figure)
      }
    }).bind(this));
  }

  figureOnDblClick(figureId) {
    let fig = this.draw2dFigures.get(figureId)
    if (fig != null) {

      // iterate through ancestors, find the first event handler
      let parent = fig;
      while (parent != null) {
        const handler = parent.getUserData().get('onDblClickAction');

        if (handler) {
          // apply event handler
          handler();
          return;

        } else {
          parent = parent.getParent();
        }
      }

      if(this.props.mode === 'Design'){
        // click into the figure
        fig = this.getRootFigure(fig);
        if(fig.getChildren().getSize()) {
          const configId = fig.getUserData().get('configId');
          const width = fig.width;
          const height = fig.height;
          this.props.pushView(this.props.designId, configId, width, height);
        }
      }
    }
  }

  getRootFigure(figure) {
    let parent;
    do {
      parent = figure.getParent();
      if(parent != null) figure = parent;
    } while(parent != null);
    return figure;
  }

  // delegate the draw2d commands to the appropriate command handlers:
  handleCommandPostExecute(cmd) {
    //console.log(this.commandNameToString(cmd))
    switch (cmd.NAME) {
      case 'draw2d.command.CommandDelete': return this.handleDeleteCommands([cmd])
      case 'draw2d.command.CommandDeleteGroup': return this.handleDeleteGroup([cmd])
      case 'draw2d.command.CommandMove':
      case 'draw2d.command.CommandResize': return this.handleChangeShapeCommands([cmd])
      case 'draw2d.command.CommandConnect': return this.handleAddConnection(cmd.connection)
      case 'draw2d.command.CommandMoveVertex': return this.handleMoveVertex(cmd)
      case 'draw2d.command.CommandMoveVertices':
      case 'draw2d.command.CommandReplaceVertices': return this.handleMoveVertices(cmd)
      case 'draw2d.command.CommandCollection': return this.handleCommandCollection(cmd.commands.data)
      default:
    }
  }

  // handles the commands which move or resize figures,
  // multiple figures can be moved at the same time
  handleChangeShapeCommands(cmds) {
    let changes = Map()
    cmds.forEach((cmd) => {
      let figureId
      if (cmd.NAME == 'draw2d.command.CommandMoveVertices') {
        figureId = cmd.line.id
      } else {
        figureId = cmd.figure.id
      }
      if (cmd.NAME === 'draw2d.command.CommandMove') {
        changes = changes.update(figureId, List(), (changedProps) => {
          // x and or y changed when moving a figure
          return changedProps
            .push(DesignerHelper.diffSet('x', cmd.newX))
            .push(DesignerHelper.diffSet('y', cmd.newY))
        });
      } else {
        changes = changes.update(figureId, (changedProps) => {
          if (changedProps === undefined) {
            changedProps = List()
          }
          if (cmd.NAME == 'draw2d.command.CommandResize') {
            // width and or height changed when resizing a figure
            return changedProps.push(DesignerHelper.diffSet('width', cmd.newWidth))
              .push(DesignerHelper.diffSet('height', cmd.newHeight))
          } else if (cmd.NAME == 'draw2d.command.CommandMoveVertices') {
            return changedProps.push(DesignerHelper.diffSet('vertex', fromJS(cmd.newVertices.data)))
          }
          return changedProps
        })
      }
    })
    this.props.changeFigures(this.props.designId, changes)
  }

  // when multiple commands are executed in a batch, draw2d executes a
  // command collection, we inspect the type of command to know what draw2d
  // tried to achieve
  handleCommandCollection(subCommands) {

    // group commands by type
    const subCommandsGrouped = {};
    for (let command of subCommands) {
      const arr = subCommandsGrouped[command.NAME] || (subCommandsGrouped[command.NAME] = []);
      arr.push(command);
    }

    // bulk apply
    for (let key in subCommandsGrouped) {
      const commandsBulk = subCommandsGrouped[key];
      switch (key) {
        case 'draw2d.command.CommandDelete':
          this.handleDeleteCommands(commandsBulk);
          break;
        case 'draw2d.command.CommandDeleteGroup':
          this.handleDeleteGroup(commandsBulk);
          break;
        case 'draw2d.command.CommandMove':
        case 'draw2d.command.CommandResize':
          this.handleChangeShapeCommands(commandsBulk);
          break;
      }
    }
  }

  // happens when a single line vertex is moved
  handleMoveVertex(cmd) {
    const id = cmd.line.id;
    const figure = this.props.design.get('figures-config').find((figure) => getFigureId(figure) === id);
    if (figure) {
      let vertices = getFigurePropMainConfig(figure, 'vertex');
      if (vertices) {
        vertices = vertices.map((vtx) => {
          if (vtx.x === cmd.origPoint.x && vtx.y === cmd.origPoint.y) {
            return cmd.newPoint;
          }
          return vtx;
        });

        const diff = DesignerHelper.diffSet('vertex', vertices);
        const changes = Map().set(id, List().push(diff));
        this.props.changeFigures(this.props.designId, changes);
      }
    }
  }

  // command happens when a connection is dragged or modified (add/remove segment)
  handleMoveVertices(cmd) {
    const diff = DesignerHelper.diffSet('vertex', fromJS(cmd.newVertices.data))
    const changes = Map().set(cmd.line.id, List().push(diff))
    this.props.changeFigures(this.props.designId, changes)
  }

  // happens when a connection is dragged from a source port to a target port
  handleAddConnection(conn) {
    // setting routedByUserInteraction because otherwise draw2d will behave
    // correctly later on when we reload the saved data (it would show a connection
    // with the initial layout isntead of the extra segments/moved segments)
    const figureConfig = Map().setIn(['mainConfig', 'data'],
      fromJS(conn.getPersistentAttributes())
        .setIn(['routingMetaData', 'routedByUserInteraction'], true))

    this.props.createFigureOnCanvas(this.props.designId, figureConfig, true)
  }

  handleDeleteCommands(deleteCmds) {
    // gather all deleted figure ids,
    // if a figure is deleted, its connections are also deleted and
    // these connections can be found in delete command.connections:
    let deleteIds = Set()
    deleteCmds.forEach((d) => {
      if (d.connections !== undefined) {
        d.connections.data.forEach((c) => {
          deleteIds = deleteIds.add(c.id)
        })
      }
      deleteIds = deleteIds.add(d.figure.id);
    })
    this.props.deleteFigures(this.props.designId, deleteIds)
  }

  handleDeleteGroup(deleteGroupCmds) {
    const deletedGroupIds = deleteGroupCmds.map((cmd) => cmd.group.id);
    this.props.deleteFigures(this.props.designId, deletedGroupIds);
  }

  // callback is ran for every figure in the new selection.
  // Since that selection can be large, and we only want to update the store for the
  // final result, "debounce" intermediate results.
  get handleFigureSelected() {
    return this.handleFigureSelectedDebounced || (this.handleFigureSelectedDebounced = _.debounce(function () {

      // ignore events resulting from syncing with store
      if (this.figuresSync.synching) return;
  
      const selection = this.draw2dCanvas.getSelection();
      const primary = selection.getPrimary() && selection.getPrimary().userData.get('configId');
      const secondary = selection.getAll().asArray()
        .map((sel) => sel.userData.get('configId'))
        .filter((configId) => configId !== primary);
  
      // if the selected figure didn't change, then no need to dispatch an action
      const designerSelection = this.design.get('selected-figure');
      if (isCanvasSelectionUnchanged(primary, secondary, designerSelection))
        return;
  
      this.props.selectFigure(this.props.designId,
        primary,
        secondary,
        'canvas')
    }, 100));
  }

  commandNameToString(command) {
    let lbl = command.NAME;
    if (command.commands !== undefined) {
      lbl += '['
      command.commands.data.forEach((c) => {
        lbl = lbl + this.commandNameToString(c) + ', '
      })
      lbl += ']'
    }
    return lbl
  }

  _getPropertyContext() {
    return {
      store: this.context.store
    };
  }
}

DesignerCanvas.contextType = ReactReduxContext;

DesignerCanvas.propTypes = {
  canvasId: PropTypes.string,
  designId: PropTypes.string.isRequired,
  design: PropTypes.instanceOf(Map).isRequired,
  executeFigureAction: PropTypes.func.isRequired,
  changeFigures: PropTypes.func.isRequired,
  createFigureOnCanvas: PropTypes.func.isRequired,
  deleteFigures: PropTypes.func.isRequired,
  selectFigure: PropTypes.func.isRequired,
  clearSelectedFigure: PropTypes.func.isRequired,
  setCanvasContextMenuToggled: PropTypes.func.isRequired,
  setWindowSize: PropTypes.func.isRequired,
  pushView: PropTypes.func.isRequired,
  splitPane: PropTypes.instanceOf(Map),
  mode: PropTypes.oneOf(['View', 'Design']).isRequired
}

export default DesignerCanvas
